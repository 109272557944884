import request from "@/utils/request";

export default {
  // 升降机监测信息
  countToday(data: any) {
    return request({
      url: "project/deviceElevator/countToday/" + data,
      method: "get",
    });
  },
  // 查询设备列表
  deviceElevatorList(args: any) {
    return request({
      url: "project/deviceElevator/map",
      method: "post",
      data: args,
    });
  },
  // 查询个人的资质证书全部信息(List)按身份证
  listQualificationByNo(arg: any) {
    return request({
      url: "project/person/listQualificationByNo",
      method: "get",
      params: arg,
    });
  },
  //查询人脸记录
  pageDriverRecord(data: any) {
    return request({
      url: "project/deviceElevator/pageDriverRecord",
      method: "post",
      data: data,
    });
  },
  //查询塔吊监测数据
  getDetailById(data: any) {
    return request({
      url: "project/deviceElevator/getDetailById/" + data,
      method: "get",
    });
  },
  //查询起重机监测数据
  getDetailByIdTower(data: any) {
    return request({
      url: "project/deviceTower/getDetailById/" + data,
      method: "get",
    });
  },
  //分页查询告警记录
  pageElevatorAlertRecord(data: any) {
    return request({
      url: "project/elevatorAlertRecord/page",
      method: "post",
      data: data,
    });
  },
  //分页查询塔吊工作记录
  pageElevatorRecord(data: any) {
    return request({
      url: "project/elevatorRecord/page",
      method: "post",
      data: data,
    });
  },
  //起重机实时监控数据
  deviceTowercountToday(data: any) {
    return request({
      url: "project/deviceTower/countToday/" + data,
      method: "get",
    });
  },

  //起重机分页查询设备详细信息
  page(data: any) {
    return request({
      url: "project/deviceTower/page",
      method: "post",
      data: data,
    });
  },
  pageDriverRecordTower(data: any) {
    return request({
      url: "project/deviceTower/pageDriverRecord",
      method: "post",
      data: data,
    });
  },
  //起重机分页查询告警记录
  pageTowerAlertRecord(data: any) {
    return request({
      url: "project/towerAlertRecord/page",
      method: "post",
      data: data,
    });
  },
  // 分页查询塔吊工作记录
  pageTowerRecord(data: any) {
    return request({
      url: "project/towerRecord/page",
      method: "post",
      data: data,
    });
  },
  //临边防护预警次数
  count(dirId: any) {
    return request({
      url: "project/deviceEdge/alarmCount/" + dirId,
      method: "get",
    });
  },
  //临边防护设备列表
  tree(projectId: any) {
    return request({
      url: "project/deviceEdge/tree/" + projectId,
      method: "get",
    });
  },
  //临边防护分页查询
  lbfhpage(data: any) {
    return request({
      url: "project/deviceEdge/historyPage",
      method: "post",
      data: data,
    });
  },
  // 卸料平台统计
  countXlpt(id: any) {
    return request({
      url: "project/deviceUnloading/countToday/" + id,
      method: "get",
    });
  },
  // 卸料平台分页1
  Xlptlist(data: any) {
    return request({
      url: "project/deviceUnloading/list",
      method: "post",
      data,
    });
  },
  // 卸料平台分页1
  Xlptpage(data: any) {
    return request({
      url: "project/deviceUnloading/page",
      method: "post",
      data,
    });
  },
  // 卸料平台实时监控
  detail(data: any) {
    return request({
      url: "project/deviceUnloading/info",
      method: "post",
      data,
    });
  },
  // 卸料平台报警记录列表
  unloadingAlertRecordPage(data: any) {
    return request({
      url: `project/deviceUnloading/unloadingAlertRecordPage`,
      method: "post",
      data,
    });
  },
  // 卸料平台监测记录列表
  unloadingRecordPage(data: any) {
    return request({
      url: `project/deviceUnloading/unloadingRecordPage`,
      method: "post",
      data,
    });
  },
};
